.container {
  width: 100%;
  display: flex;
}
.content {
  width: 100%;
  margin-left: 300px;
}
.content_inner {
  width: 100%;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  padding: var(--page_padding);
  background-color: var(--l_p_bg);
}
@media screen and (max-width: 710px) {
  .content {
    margin-left: 0px;
  }
}
